import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"

import SyncBionicsIcon from "../components/SyncBionicsIcon"

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component"
import "react-vertical-timeline-component/style.min.css"

const History = ({ data }) => (
  <Layout>
    <SEO title="Company History" />
    <Container fluid className="history-background">
      <Row>
        <Col>
        <h1 style={{
        textAlign: 'center',
        marginTop: '2rem',
        fontWeight: 'bold',
        fontSize: '4rem'
      }}>Company History</h1>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#36762F", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  #36762F" }}
          date="2020"
          iconStyle={{ background: "#36762F", color: "#fff" }}
          icon={<SyncBionicsIcon />}
        >
          <h3 className="vertical-timeline-element-title">Sync Bionics Launch</h3>
          <p>
          A subsidiary of Ripple Ventures. Sync Bionics was founded to focus on our line of products specifically designed to improve the lives of people with amputations.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#36762F", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  #36762F" }}
          date="2020"
          iconStyle={{ background: "#36762F", color: "#fff" }}
          icon={<SyncBionicsIcon />}
        >
          <h3 className="vertical-timeline-element-title">Submission to FDA for Wave Clinical Testing</h3>
          <p>
          A subsidiary of Ripple Ventures. Sync Bionics was founded to focus on our line of products specifically designed to improve the lives of people with amputations.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#36762F", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  #36762F" }}
          date="2019"
          iconStyle={{ background: "#36762F", color: "#fff" }}
          icon={<SyncBionicsIcon />}
        >
          <h3 className="vertical-timeline-element-title">Heal Grant</h3>
          <p>
          We received an NIH HEAL grant to fund the development of the XXX to treat phantom limb pain and restore sensation from the missing limb.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#36762F", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  #36762F" }}
          date="2019"
          iconStyle={{ background: "#36762F", color: "#fff" }}
          icon={<SyncBionicsIcon />}
        >
          <h3 className="vertical-timeline-element-title">Pre-Clinical Testing for Wave Complete</h3>
          <p>
          We finalized the safety and function testing for the Wave, paving the way for clinical testing.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#36762F", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  #36762F" }}
          date="2017"
          iconStyle={{ background: "#36762F", color: "#fff" }}
          icon={<SyncBionicsIcon />}
        >
          <h3 className="vertical-timeline-element-title">Wave Design Finalized</h3>
          <p>
          We finalized the safety and function testing for the Wave, paving the way for clinical testing.
          </p>
          <Img fluid={data.wavesm.childImageSharp.fluid} />
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#36762F", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  #36762F" }}
          date="2016"
          iconStyle={{ background: "#36762F", color: "#fff" }}
          icon={<SyncBionicsIcon />}
        >
          <h3 className="vertical-timeline-element-title">First Human Study with Wave Technology</h3>
          <p>
          The first people with upper limb amputations to use the Wave technology were implanted through the skin at the University of Utah, enabling simultaneous control of multiple joints of a prosthetic limb. See our News and Events section for more details.
          </p>
        </VerticalTimelineElement>
        
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#36762F", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  #36762F" }}
          date="2015"
          iconStyle={{ background: "#36762F", color: "#fff" }}
          icon={<SyncBionicsIcon />}
        >
          <h3 className="vertical-timeline-element-title">Ripple Named as Systems Integrator for DARPA HAPTIX Project</h3>
          <p>
          The DARPA HAPTIX Project was initiated to provide wounded warriors with the most advanced upper-limb prosthetic technology, enabling near-natural control and sensation from prosthetic limbs. As a systems integrator we provided key technology for this program while advancing our myoelectric recording and stimulation systems.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#36762F", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  #36762F" }}
          date="2012"
          iconStyle={{ background: "#36762F", color: "#fff" }}
          icon={<SyncBionicsIcon />}
        >
          <h3 className="vertical-timeline-element-title">First Wave Prototype</h3>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#36762F", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  #36762F" }}
          date="2010"
          iconStyle={{ background: "#36762F", color: "#fff" }}
          icon={<SyncBionicsIcon />}
        >
          <h3 className="vertical-timeline-element-title">National Institutes of Health Award for Early Development of the Wave</h3>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#36762F", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  #36762F" }}
          date="2006"
          iconStyle={{ background: "#36762F", color: "#fff" }}
          icon={<SyncBionicsIcon />}
        >
          <h3 className="vertical-timeline-element-title">Start of the Wave Project</h3>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#36762F", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  #36762F" }}
          date="2005"
          iconStyle={{ background: "#36762F", color: "#fff" }}
          icon={<SyncBionicsIcon />}
        >
          <h3 className="vertical-timeline-element-title">Lauch of Ripple</h3>
        </VerticalTimelineElement>

      </VerticalTimeline>
        </Col>
      </Row>
      
    </Container>
  </Layout>
)

export const query = graphql`
  query {
    wavesm: file(relativePath:{eq:"wave@2x.png"}) {
      childImageSharp {
        fluid(quality:90, maxWidth:1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default History
