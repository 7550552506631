import React from 'react'

const SyncBionicsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 204.09639 215.89865"
    fill="#ffffff"
  >
    <path
      class="cls-1" 
      d="M108.0571,108.325l-12.8418,8.56c-15.7036-7.8808-32.541,5.4424-30.9536,21.4297v.1133l.0566.3975c3.3731,25.8232,38.4946,23.6406,42.4341.8213a21.37824,21.37824,0,0,0-5.6406-18.3965l2.2959-1.5303h0l55.956-37.3325a22.485,22.485,0,0,1,.17-12.8975,23.049,23.049,0,0,1,4.3085-7.9087Q151.129,44.8711,138.4155,28.13255a9.13493,9.13493,0,0,0,1.2197-.085,8.98687,8.98687,0,1,0-10.0635-7.7954,8.842,8.842,0,0,0,3.5147,6.0381l8.8447,11.6787q-.5112.08505-1.0205.17a19.2202,19.2202,0,1,1,13.0391-7.7388q13.3506,17.6037,26.7021,35.1499a10.7007,10.7007,0,1,0,4.4785.6802l-9.2119-12.1324a22.41751,22.41751,0,0,1,12.1319.3687,22.79144,22.79144,0,1,1-21.6846,38.9766l-46.9141,31.2934v.0283a35.685,35.685,0,0,1-23.1308,45.3545h0a35.64425,35.64425,0,0,1-44.7583-23.3291h0a35.69745,35.69745,0,0,1,56.4946-38.4653Z"
    />
    <path
      class="cls-1" 
      d="M83.1967,215.89865c26.7881,0,48.9541-9.666,63.4395-27.4385l-22.7617-20.9765a49.50822,49.50822,0,1,1-4.0821-67.2378l25.9649-17.0645c-14.4844-16.9228-36.3115-26.1919-62.333-26.1919C35.5747,56.98945,0,90.12575,0,136.44355c0,46.3184,35.5747,79.4551,83.1967,79.4551Z"
    />
  </svg>
)

export default SyncBionicsIcon